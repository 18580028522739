export const selector = '.filter'

export function hydrate (element) {
  const selector = element.dataset.target
    ? `[data-filterable-by='${element.dataset.target}']`
    : '[data-filterable]'

  // Prepare filterable items
  const items = Array.from(document.querySelectorAll(selector))
  for (const item of items) {
    if (item.dataset.filter) {
      item.dataset.filter = sanitize(item.dataset.filter, { uppercase: true })
      continue
    }

    item.dataset.filter = ''
    for (const text of item.querySelectorAll('[data-value]')) {
      item.dataset.filter += '|' + sanitize(text.innerText, { uppercase: true })
    }
  }

  // Filter
  const input = element.querySelector('input')
  input.addEventListener('input', () => {
    window.scrollTo(0, 0)
    const needle = sanitize(input.value, { uppercase: true })

    for (const item of items) {
      const match = needle && item.dataset.filter.includes(needle)
      item.style.display = (!needle || needle === '' || match) ? '' : 'none'

      for (const text of item.querySelectorAll('[data-value]')) {
        if (!text.dataset.withoutMark) text.dataset.withoutMark = text.innerText
        text.innerHTML = text.dataset.withoutMark
        if (!match) continue

        text.innerHTML = sanitize(text.innerText).replace(new RegExp(needle, 'gi'), '<mark>$&</mark>')
      }
    }
  })
}

function sanitize (string = '', { uppercase = false } = {}) {
  string = string
    .replace(/\u00a0/g, ' ')
    .replace(/\s+/g, ' ')
    .replace(/’/g, "'")

  if (string === ' ') return
  return uppercase ? string.toUpperCase() : string
}

import lozad from 'lozad'

document.addEventListener('DOMContentLoaded', () => {
  // Find and hydrate all components
  for (const { selector, hydrate } of [
    require('components/Filter'),
    require('components/Gallery'),
    require('components/List'),
    require('components/Photoswipe'),
    require('components/Picklist'),
    require('components/Search'),
    require('components/Slideshow')
  ]) {
    for (const element of document.querySelectorAll(selector)) {
      hydrate(element)
    }
  }

  // Lazyloading
  const lazys = document.querySelectorAll('img[data-lazyload=true]')
  for (const img of lazys) img.onload = () => img.setAttribute('data-decoded', true)
  lozad(lazys).observe()
})

export const selector = '.list'

export function hydrate (element) {
  const headers = element.querySelectorAll('.list__header[data-sortable]')
  const items = element.querySelectorAll('.list__item')

  for (const header of headers) {
    const index = Array.from(header.parentNode.children).indexOf(header)

    ;(header.querySelector('span') ?? header).addEventListener('click', () => {
      // Update headers
      for (const h of headers) if (h !== header) delete h.dataset.sorted
      header.dataset.sorted = header.dataset.sorted === 'asc' ? 'desc' : 'asc'

      // Sort
      const sorted = Array.from(items).sort((a, b) => {
        const val = el => {
          const cells = Array.from(el.querySelectorAll('.list__cell'))
          return (cells[index]?.dataset.value ?? cells[index]?.textContent).toLowerCase()
        }

        return header.dataset.sorted === 'asc'
          ? val(a).localeCompare(val(b), undefined, { numeric: true })
          : val(b).localeCompare(val(a), undefined, { numeric: true })
      })

      // Update order
      for (const item of sorted) {
        item.parentNode.append(item)
      }
    })
  }

  for (const item of items) {
    const accordion = item.querySelector('.list__accordion')
    if (!accordion) continue
    item.addEventListener('click', e => {
      const shouldScroll = !item.classList.contains('is-open')
      for (const i of items) {
        i.classList.toggle('is-open', i === item)
      }

      // Ensure clicked item stays under cursor once other accordions collapsed
      if (shouldScroll) {
        document.documentElement.scrollTop = item.offsetTop - (e.clientY - 20)
      }
    })
  }
}

export const selector = '.picklist'

const PADDING = 20

export function hydrate (element) {
  const content = element.querySelector('.picklist__content')
  // Ensure picklist stays inside screen
  element.addEventListener('toggle', e => {
    const { left, width } = content.getBoundingClientRect()

    const delta = Math.ceil(window.innerWidth - (left + width)) - PADDING

    if (delta < 0) element.style.setProperty('--offset-x', delta + 'px')
    else element.style.removeProperty('--offset-x')
  })

  // Close by clicking outside
  document.addEventListener('click', e => {
    const path = e.composedPath()
    if (path && !path.includes(element)) element.removeAttribute('open')
  })
}

export const selector = '.gallery'

export function hydrate (element) {
  const filter = {}
  const items = element.querySelectorAll('figure')

  // Bind
  for (const picker of document.querySelectorAll('.picklist')) {
    for (const input of picker.querySelectorAll('input[type=checkbox][value]')) {
      input.addEventListener('change', e => {
        if (input.checked) add(picker.name, input.value)
        else remove(picker.name, input.value)
      })
    }
  }

  function add (field, value) {
    if (!filter[field]) filter[field] = new Set()
    filter[field].add(value)
    update()
  }

  function remove (field, value) {
    if (!filter[field]) return
    filter[field].delete(value)
    update()
  }

  function update () {
    for (const item of items) {
      // AND filter
      const match = (() => {
        for (const field in filter) {
          if (!filter[field].size) continue

          if (!item.dataset[field]) return false
          for (const value of filter[field]) {
            if (!item.dataset[field].includes(value)) return false
          }
        }
        return true
      })()

      item.classList.toggle('is-hidden', !match)
    }
  }
}

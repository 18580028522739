import Flickity from 'flickity'
import Bowser from 'bowser'

export const selector = '.slideshow'

export function hydrate (element) {
  const length = Array.from(element.querySelectorAll('figure')).length
  if (length <= 1) return

  const isMobile = Bowser
    .getParser(window.navigator.userAgent)
    .is('mobile')

  new Flickity(element, { // eslint-disable-line no-new
    setGallerySize: true,
    imagesLoaded: true,
    lazyLoad: true,
    resize: true,
    contain: true,
    auto: true,
    cellAlign: 'left',
    prevNextButtons: true,
    pageDots: false,
    wrapAround: element.dataset.wrapAround,
    accessibility: true,
    selectedAttraction: isMobile ? 0.8 : 0.05,
    friction: isMobile ? 1 : 0.4,
    dragThreshold: isMobile ? 75 : 3,
    arrowShape: 'M70 83.3679C70 82.3645 69.5995 81.4561 68.9521 80.7987L68.9554 80.7953L38.6442 49.9996L68.9554 19.2038L68.9525 19.2008C69.5997 18.5435 70 17.6353 70 16.6321C70 14.6261 68.3995 13 66.4253 13C65.4378 13 64.5439 13.4068 63.897 14.0644L63.8918 14.0592L31.0487 47.4273C29.6504 48.8479 29.6504 51.1512 31.0487 52.5718L63.8918 85.9399L63.8965 85.9351C64.5435 86.593 65.4376 87 66.4253 87C68.3995 87 70 85.3739 70 83.3679Z'
  })
}

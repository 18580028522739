import Photoswipe from 'photoswipe'
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

let photoswipe

export const selector = '.pswp'
export function hydrate (element) {
  for (const figure of document.body.querySelectorAll('[data-pswp-src]')) {
    figure.addEventListener('click', handleClick(figure))
    figure.classList.add('has-photoswipe')
  }

  function handleClick (target) {
    return function (e) {
      e.preventDefault()
      const figures = Array.from(document.body.querySelectorAll('[data-pswp-src]:not(.is-hidden)'))

      photoswipe = new Photoswipe(
        element,
        PhotoswipeUIDefault,
        figures.map((figure, index) => ({
          index,
          element: figure.querySelector('image'),
          src: figure.getAttribute('data-pswp-src'),
          w: +figure.getAttribute('data-pswp-width'),
          h: +figure.getAttribute('data-pswp-height'),
          title: figure.querySelector('figcaption')?.innerHTML
        })),
        {
          index: figures.indexOf(target),
          bgOpacity: 0.8,
          history: false,
          allowPanToNext: false,
          barsSize: { top: 0, bottom: 0 },
          captionEl: true,
          fullscreenEl: false,
          closeEl: true,
          zoomEl: false,
          shareEl: true,
          counterEl: false,
          preloaderEl: false,
          showHideOpacity: true,
          timeToIdle: null,
          timeToIdleOutside: null,
          tapToToggleControls: false,
          maxSpreadZoom: 1,
          pinchToClose: false
        }
      )

      photoswipe.init()
    }
  }
}

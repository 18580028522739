export const selector = '.search'

export function hydrate (element) {
  const toggle = document.getElementById('toggleSearch')
  const input = element.querySelector('input[type="search"]')
  toggle.addEventListener('change', e => {
    if (!toggle.checked) return
    input.focus()
    input.select()
  })
}
